import React from 'react';
import {TablePaginationConfig} from "antd";
import {FilterValue} from "antd/es/table/interface";
declare global {
    interface Window {
        myBaseURL: any;
    }
}
//登录获取二维码api
export const login_pc_qrcode = window.myBaseURL.url+"/auth/weixin/auth/qrcode?state=";

//获取资料下载地址
export const download_doc = window.myBaseURL.url+"/download/doc/";

//腾讯地图接口：开发密钥(key)
export const tencent_maps_geolocation_key = "KAEBZ-GHS6F-C4IJG-JMJ4W-LXOLT-A3B3R";

//腾讯地图接口：调用来源，一般为您的应用名称
export const tencent_maps_geolocation_referer = "ecimpui";

//token名称
export const ecimpWebUserTokenKey = "ecimpWebUserToken";

//token存放时间
export const ecimpWebUserTokenTimeKey = "ecimpWebUserTokenTime";

//token存储时间
export const ecimpWebUserTokenStorageTimeKey = "ecimpWebUserTokenStorageTime";

//用户手机号
export const ecimpWebUserPhoneKey = "ecimpWebUserPhone";

//用于存储用户角色
export const ecimpWebUserRoleKey = "ecimpWebUserRole";

export const windowOpenMap = new Map();

const getCssSelectorRoot = (selectors:string | null,key:string) => {
    let root = document.querySelector(selectors==null?":root":selectors);
    if(root !=null){
        return getComputedStyle(root).getPropertyValue(key)
    }
    console.log(root)
    return "";
}

//全局css
export const global_css = {
    headerBackgroundColor: getCssSelectorRoot(null,"--headerBackgroundColor"),
    headerMenuBackgroundColor:getCssSelectorRoot(null,"--headerMenuBackgroundColor"),
    headerMenuColor: getCssSelectorRoot(null,"--headerMenuColor"),
    contentBackgroundColor: getCssSelectorRoot(null,"--contentBackgroundColor"),
    contentColor: getCssSelectorRoot(null,"--contentColor"),
    contentTabsColor: getCssSelectorRoot(null,"--contentTabsColor"),
    enterpriseMenuColor: getCssSelectorRoot(null,"--enterpriseMenuColor"),
    enterpriseMenuColorActive: getCssSelectorRoot(null,"--enterpriseMenuColorActive"),
    enterpriseMenuBackgroundColor: getCssSelectorRoot(null,"--enterpriseMenuBackgroundColor"),
    cardPreviewBackgroundColor: getCssSelectorRoot(null,"--cardPreviewBackgroundColor"),
    modalBodyStyleBackgroundColor: getCssSelectorRoot(null,"--modalBodyStyleBackgroundColor"),
};

//卡片报警详情提示样式
export const alarm_hint_tooltip_css:React.CSSProperties = {
    color:"#282829",
    marginTop:"-8px",
    width:"170px",
    borderRadius:"6px"
};

//卡片名称对应map
export const dataViewTypeNameMap = new Map<string,string>([['crane','起重机'],['qy3','调压调速'],['conventional','ecimp'],
    ['inverter','变频调速'],['qy3-non-config','调压调速'],['qy3_test','调压调速'],['jsr2t','jsr2t'],['','']]);

//机构select列表
export const motionSelectOptions = [{label:'主起升',value:2},{label:'副起升',value:3},{label:'副副起升',value:4},
    {label:'大车',value:5},{label:'主小车',value:6},{label:'副小车',value:7},{label:'整车',value:12}];

/*
分页配置
 */
export let publicPagination =  {current: 1,pageSize:10,pageSizeOptions:[10,20,50]};
export interface TableParams {
    pagination?: TablePaginationConfig;
    sortField?: string;
    sortOrder?: string;
    filters?: Record<string, FilterValue | null>;
}
export let paginationTemplate = {
    pagination: {
        current: publicPagination.current,
        pageSize: publicPagination.pageSize,
        pageSizeOptions:publicPagination.pageSizeOptions,
        showQuickJumper:true,
        showTotal:(total: number, range: [number, number])=>`总共 ${total} 条`
    },
}
