import React from "react";
import * as Highcharts from "highcharts";

interface CardData{
    param:any;
    seriesData:any;
    realData?:any;
}
const CardHighchartsPie: React.FC<CardData> = (cardData) => {

    React.useEffect(() => {
        let seriesData = new Array();
        if(cardData.seriesData != null && cardData.seriesData.length > 0){
            for(let i=0;i<cardData.seriesData?.length ?? 0;i++) {
                seriesData.push({
                    name: cardData.seriesData[i].alarmName,
                    y: cardData.seriesData[i].sum,
                    sliced: false,
                    selected: true,
                    result: cardData.seriesData[i].result
                });
            }
        }
        (Highcharts as any).chart(document.getElementById(cardData.param.id+'PreviewPie'), {
            credits:{enabled:false},
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor:"#e6e6e6"
            },
            title: {
                text: ''
            },
            tooltip: {
                useHTML: true,
                pointFormat: "<span style=\"max-width:200px;word-wrap:break-word;word-break:break-all;\">{series.name}: </b>{point.percentage:.1f}%<br/>报警现象: {point.result}</span>"
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            color: (Highcharts.theme && (Highcharts as any).theme.contrastTextColor) || 'black'
                        }
                    }
                }
            },
            series: [{
                name: '比例',
                colorByPoint: true,
                data: seriesData
            }]
        });
    },[]);
    return (
        <>
            <div style={{border: "1px solid #a3a3a3"}}></div>
            <div id={cardData.param.id+'PreviewPie'} style={{height:'226px',background:"#e6e6e6"}}></div>
        </>
    );
};

export default CardHighchartsPie;