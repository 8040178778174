import {Card, Tooltip, Tabs} from 'antd';
import React, {useState} from 'react';
import '../../../css/cardPreview.css';
import {getMotionType} from "../../../utils/PublicMethodTsx";
import CardHighchartsPie from "../../../utils/module/CardHighchartsPie";
import {alarm_hint_tooltip_css as alarmHintTooltipCss} from "../../../utils/PublicConstant";
import Icon from "@ant-design/icons";
import alarmImg from "../../../imagers/icon/alarm.png";
import CardDigitalModule from "../../../utils/module/CardDigitalModule";

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const ConventionalPreview: React.FC<CardData> = (cardData) => {
    const [data,setData] = useState<any>();

    const [trackRealData,setTrackRealData] = useState<any[]>([]);
    const [trackSeries,setTrackSeries] = useState<any[]>([]);
    const [name1,setName1] = useState<String>();
    const [name2,setName2] = useState<String>();
    const [motionType,setMotionType] = useState<boolean|null>();

    React.useEffect(() => {
        let items = cardData.param;
        let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        setData(newData ?? data);
        setMotionType(getMotionType(items?.mechId));
        if(items?.data != null) {
            let realData = new Array(),series = new Array();
            let type = getMotionType(items?.mechId);
            if(type){
                series.push("起重量");
                series.push("起升高度");
                series.push("起升速度");
                realData.push(items?.data?.weight);
            }else if(type === false){
                series.push("行程");
                series.push("行走速度");
            }
            realData.push(items?.data?.distance);
            realData.push(items?.data?.speed);
            setTrackRealData(realData);
            setTrackSeries(series);
        }
        if(items?.mechId == 2 || items?.mechId == 3 || items?.mechId == 4 || items?.mechId == 8){
            setName1("上");setName2("下");
        }else if(items?.mechId == 5){
            setName1("左");setName2("右");
        }else if(items?.mechId == 6 || items?.mechId == 7){
            setName1("前");setName2("后");
        }else{
            setName1("");setName2("");
        }
    },[cardData.realData]);
    return (
        <>
            <div className={"preview-tabs-head"}  style={{height:"225px"}}>
                <Tabs
                    defaultActiveKey="1" type="card" size={"middle"} tabBarStyle={{}}
                    items={[
                        {
                            label: <span className="preview-tabs-head-label">ecimp</span>,
                            key: "1",
                            children:<div className={"preview-tabs-content"}>
                                <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                                    <tbody>
                                    <tr>
                                        <td width="25%">
                                            <CardDigitalModule digitalName={name1+'限位'} digitalValue={data?.limitObvs}></CardDigitalModule>
                                        </td>
                                        <td width="25%">
                                            <CardDigitalModule digitalName={name1+'预限位'} digitalValue={data?.preLimitObvs}></CardDigitalModule>
                                        </td>
                                        <td width="25%">
                                            <CardDigitalModule digitalName={name2+'限位'} digitalValue={data?.limitRevs}></CardDigitalModule>
                                        </td>
                                        <td width="25%">
                                            <CardDigitalModule digitalName={name2+'预限位'} digitalValue={data?.preLimitRevs}></CardDigitalModule>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} width="50%">输入档位：{data?.gearMerge}</td>
                                        <td colSpan={2} width="50%">{motionType &&<span>起重量：{data?.weight}</span>}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} width="50%">{motionType?'起升高度':'行程'}：{data?.distance}</td>
                                        <td colSpan={2} width="50%">{motionType?'起升速度':'行走速度'}：{data?.speed}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} width="50%">累计运行：{data?.devRunTime}</td>
                                        <td colSpan={2} width="50%">设备保养：{data?.usageState}</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={3} width="75%">
                                            <div style={{display:"flex",width:'260px'}} id={"alarmDiv"}>
                                                <div style={{margin:"2px 20px 0px 0px"}}>故障提示：{data?.fault}</div>
                                                <div style={{overflow:"hidden",flex:"1"}}>
                                                    <Tooltip title={data?.state} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                        <div className="alarm-hint-tooltip-content"><span className="cancel-bold">{data?.state}</span></div>
                                                    </Tooltip>
                                                </div>
                                                <div style={{margin:"0px 3px"}}>
                                                    <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px",display:data?.fault === '0-无故障'?"none":"block"}}/>)}/>
                                                </div>
                                            </div>
                                        </td>
                                        <td colSpan={1}>
                                            {
                                                motionType&&<CardDigitalModule digitalName="制动输出" digitalValue={data?.drvBrake}></CardDigitalModule>
                                            }
                                        </td>
                                    </tr>
                                    {
                                        motionType&&<tr>
                                            <td width="25%">
                                                <CardDigitalModule digitalName="制动器1" digitalValue={data?.brake1}></CardDigitalModule>
                                            </td>
                                            <td width="25%">
                                                <CardDigitalModule digitalName="制动器2" digitalValue={data?.brake2}></CardDigitalModule>
                                            </td>
                                            <td width="25%">
                                                <CardDigitalModule digitalName="制动器3" digitalValue={data?.brake3}></CardDigitalModule>
                                            </td>
                                            <td width="25%">
                                                <CardDigitalModule digitalName="制动器4" digitalValue={data?.brake4}></CardDigitalModule>
                                            </td>
                                        </tr>
                                    }
                                    </tbody>
                                </table>
                            </div>
                        }]}
                />

            </div>
            <div className={"preview-tabs-head"} style={{marginTop:"10px"}}>
                <Tabs defaultActiveKey="1" type="card" size={"middle"} style={{}} onChange={(activeKey:string)=>{}}
                      items={[
                          {
                              label:<span className="preview-tabs-head-label">报警显示</span>,
                              key:"1",
                              children:<CardHighchartsPie param={cardData.param} seriesData={cardData.param.data?.alarmSumItems} />
                          }/* ,{
                                  label:<span className="preview-tabs-head-label">轨迹曲线</span>,
                                  key:"2",
                                  children:<CardHighchartsSpline tickPositions={null} realData={trackRealData} param={cardData.param} series={trackSeries} />
                              }*/
                      ]}
                />
            </div>
        </>
    );
};

export default ConventionalPreview;