import {Tooltip, Tabs} from 'antd';
import React, {useState} from 'react';
import '../../../css/cardPreview.css';
import {getCardDigital, getParent} from "../../../utils/PublicMethodTsx";
import {alarm_hint_tooltip_css as alarmHintTooltipCss} from "../../../utils/PublicConstant";
import Icon from '@ant-design/icons';
import alarmImg from  "../../../imagers/icon/alarm.png";
import CardEquipmentTooltip from "../../../utils/module/CardEquipmentTooltip";
import CardHighchartsPie from "../../../utils/module/CardHighchartsPie";
import CardDigitalModule from "../../../utils/module/CardDigitalModule";

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const QY3APreview: React.FC<CardData> = (cardData) => {
    const [data,setData] = useState({currentU:'',currentV:'',currentW:'',gearMerge:'',speed:'',state:'',
        fault:'',brake:false,acce1:false,acce2:false,acce3:false,voltageU:"",usageState:"",devRunTime:"",voltageV:"",voltageW:""});
    React.useEffect(() => {
        let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        setData(newData ?? data);
        /*if(cardData.realData != null && cardData.fatherId==cardData.realData.id){
           let newData =  cardData.realData.subItems[cardData.realData.subItems.findIndex((item:any)=>item.id===cardData.param.id)].data;
           if(newData != null)
               setData(newData);
        }*/
    },[cardData.realData]);
    React.useEffect(() => {
        /*(Highcharts as any).chart(document.getElementById(cardData.param.id+'preview'), {
            credits:{enabled:false},
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                type: 'pie',
                backgroundColor:"#e6e6e6"
            },
            title: {
                text: ''
            },
            tooltip: {
                pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
            },
            plotOptions: {
                pie: {
                    allowPointSelect: true,
                    cursor: 'pointer',
                    dataLabels: {
                        enabled: true,
                        format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                        style: {
                            color: (Highcharts.theme && (Highcharts as any).theme.contrastTextColor) || 'black'
                        }
                    }
                }
            },
            series: [{
                name: '比例',
                colorByPoint: true,
                data: [{
                    name: '其他',
                    y: 61.41,
                    sliced: false,
                    selected: true
                }, {
                    name: '晶闸管过热',
                    y: 11.84
                }, {
                    name: '电机短路',
                    y: 10.85
                }, {
                    name: '电机缺U相',
                    y: 4.67
                }, {
                    name: '电机缺W相',
                    y: 4.18
                }, {
                    name: '电机无电流反馈',
                    y: 1.64
                }, {
                    name: '电源故障',
                    y: 1.6
                }, {
                    name: '下降超速',
                    y: 1.2
                }]
            }]
        });*/
    },[]);
    const [open, setOpen] = useState(false);
    const getName = ( node:any)=>{
        let name = getParent(node.enterpriseList,node.key,"lable");
        let val = ""
        name.forEach((item:any,index)=> {
            if(item != "设备层级")
            val += item+"/";
        });
        return val;
    }
    return (
        <>
            <div className={"preview-tabs-head"}>
                <Tabs defaultActiveKey="1" type="card" size={"middle"} tabBarStyle={{}}
                      items={[
                          {
                              label:<span className="preview-tabs-head-label">主用</span>,
                              key:"1",
                              children:<div className={"preview-tabs-content"}>
                                  <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                                      <tbody>
                                      <tr>
                                          <td colSpan={2} width="50%" className="equipment-name-background">
                                              <CardEquipmentTooltip name={"设备名称"} nameLabel={"绕线电机交直流调速器"}/>
                                          </td>
                                          <td colSpan={2} width="50%">
                                              <CardEquipmentTooltip name={"型号规格"} nameLabel={""}/>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td colSpan={2} width="50%">输入档位：{data.gearMerge}</td>
                                          <td colSpan={2} width="50%">电机转速：{data.speed}</td>
                                      </tr>
                                      <tr>
                                          <td width="25%" style={{borderRight:"0px"}}>定子电流：</td>
                                          <td style={{borderRight:"0px"}}>Iu：{data.currentU}</td>
                                          <td style={{borderRight:"0px"}}>Iv：{data.currentV}</td>
                                          <td style={{borderRight:"0px"}}>Iw：{data.currentW}</td>
                                      </tr>
                                      <tr>
                                          <td width="25%" style={{borderRight:"0px"}}>转子电流：</td>
                                          <td style={{borderRight:"0px"}}>Iu：{}</td>
                                          <td style={{borderRight:"0px"}}>Iv：{}</td>
                                          <td>Iw：{}</td>
                                      </tr>
                                      {/* <tr>
                                                <td width="25%" style={{borderRight:"0px"}}>转子电压：</td>
                                                <td style={{borderRight:"0px"}}>Vu：{}</td>
                                                <td style={{borderRight:"0px"}}>Vv：{}</td>
                                                <td>Vw：{}</td>
                                            </tr>*/}
                                      <tr>
                                          <td colSpan={2} width="50%">动作次数：{}</td>
                                          <td colSpan={2} width="50%">累计运行：{data.devRunTime}</td>
                                      </tr>
                                      <tr>
                                          <td colSpan={2} width="50%">晶闸管温度：{}</td>
                                          <td colSpan={2} width="50%">设备保养：{data.usageState}</td>
                                      </tr>
                                      <tr>
                                          <td colSpan={4}>
                                              <div style={{display:"flex",width:'350px'}} id={"alarmDiv"}>
                                                  <div style={{margin:"2px 20px 0px 0px"}}>故障提示：{data.fault}</div>
                                                  <div style={{overflow:"hidden",flex:"1"}}>
                                                      <Tooltip title={data.state} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                          <div className="alarm-hint-tooltip-content"><span className="cancel-bold">{data.state}</span></div>
                                                      </Tooltip>
                                                  </div>
                                                  <div style={{margin:"0px 3px"}}>
                                                      <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px",display:data.fault === '0-无故障'?"none":"block"}}/>)}/>
                                                  </div>
                                              </div>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td width="25%">
                                              <CardDigitalModule digitalName="一级切阻" digitalValue={data?.acce1}></CardDigitalModule>
                                          </td>
                                          <td width="25%">
                                              <CardDigitalModule digitalName="二级切阻" digitalValue={data?.acce2}></CardDigitalModule>
                                          </td>
                                          <td width="25%">
                                              <CardDigitalModule digitalName="三级切阻" digitalValue={data?.acce3}></CardDigitalModule>
                                          </td>
                                          <td width="25%">
                                              <CardDigitalModule digitalName="制动输出" digitalValue={data?.brake}></CardDigitalModule>
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </div>
                          }
                      ]}
                />
            </div>
            <div className={"preview-tabs-head"} style={{marginTop:"10px"}}>
                <Tabs defaultActiveKey="1" type="card" size={"middle"} style={{}} onChange={(activeKey:string)=>{}}
                      items={[
                          {
                              label:<span className="preview-tabs-head-label">报警显示</span>, key:"1",
                              children:<CardHighchartsPie param={cardData.param} seriesData={cardData.param.data?.alarmSumItems} />
                          }/*, {
                                label:<span className="preview-tabs-head-label">轨迹曲线</span>, key:"2",
                                children:<CardHighchartsSpline tickPositions={null} realData={[data?.currentU,data?.currentV,data?.currentW]} param={cardData.param} series={['U相电流','V相电流','W相电流']} />
                            }*/
                      ]}
                />
            </div>
        </>
    );
};

export default QY3APreview;