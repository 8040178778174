import {Card, Tooltip, Tabs, Row, Col} from 'antd';
import * as Highcharts from 'highcharts';
import React, {useState} from 'react';
import '../../../css/cardPreview.css';
import {getCardHeadPath, getParent} from "../../../utils/PublicMethodTsx";
import {alarm_hint_tooltip_css as alarmHintTooltipCss} from "../../../utils/PublicConstant";
import Icon from '@ant-design/icons';
import alarmImg from  "../../../imagers/icon/alarm.png";
import CardHeadExtra from "../../../utils/module/CardHeadExtra";
import CardEquipmentTooltip from "../../../utils/module/CardEquipmentTooltip";
import CardHighchartsSpline from "../../../utils/module/CardHighchartsSpline";
import CardHighchartsPie from "../../../utils/module/CardHighchartsPie";

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const QY3ATestPreview: React.FC<CardData> = (cardData) => {
    const [data,setData] = useState({currentU:'',currentV:'',currentW:'',gearMerge:'',speed:'',state:'',
        fault:'',brake:false,acce1:false,acce2:false,acce3:false,voltageU:"",voltageV:"",voltageW:""});
    React.useEffect(() => {
        let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        setData(newData ?? data);
    },[cardData.realData]);
    React.useEffect(() => {
    },[]);
    const [open, setOpen] = useState(false);
    const getName = ( node:any)=>{
        let name = getParent(node.enterpriseList,node.key,"lable");
        let val = ""
        name.forEach((item:any,index)=> {
            if(item != "设备层级")
                val += item+"/";
        });
        return val;
    }
    return (
        <>
            <div className={"preview-tabs-head"}>
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size={"middle"}
                    tabBarStyle={{}}
                    items={[
                        {
                            label:<span className="preview-tabs-head-label">主用</span>,
                            key:"1",
                            children:<div className={"preview-tabs-content"}>
                                <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                                    <tbody>
                                    <tr>
                                        <td colSpan={2} width="50%" className="equipment-name-background">
                                            <CardEquipmentTooltip name={"设备名称"} nameLabel={"绕线电机交直流调速器"}/>
                                        </td>
                                        <td colSpan={2} width="50%">
                                            <CardEquipmentTooltip name={"型号规格"} nameLabel={""}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} width="50%">输入档位：{data.gearMerge}</td>
                                        <td colSpan={2} width="50%">电机转速：{data.speed}</td>
                                    </tr>
                                    <tr>
                                        <td width="25%" style={{borderRight:"0px"}} colSpan={4}>
                                            <Row>
                                                <Col span={10}>定子电流：Iu：{data.currentU}</Col>
                                                <Col span={7}>Iv：{data.currentV}</Col>
                                                <Col span={7}>Iw：{data.currentW}</Col>
                                            </Row>
                                        </td>
                                        {/* <td style={{borderRight:"0px"}}>Iu：{data.currentU}</td>
                                            <td style={{borderRight:"0px"}}>Iv：{data.currentV}</td>
                                            <td>Iw：{data.currentW}</td>*/}
                                    </tr>
                                    <tr>
                                        <td width="25%" style={{borderRight:"0px"}} colSpan={4}>
                                            <Row>
                                                <Col span={10}>定子电压：Uu：{data.voltageV}</Col>
                                                <Col span={7}>Uv：{data.voltageV}</Col>
                                                <Col span={7}>Uw：{data.voltageW}</Col>
                                            </Row>
                                        </td>
                                        {/*<td style={{borderRight:"0px"}}>Vu：{data.voltageU}</td>
                                            <td style={{borderRight:"0px"}}>Vv：{data.voltageV}</td>
                                            <td>Vw：{data.voltageW}</td>*/}
                                    </tr>
                                    <tr>
                                        <td colSpan={2} width="50%">动作次数：{}</td>
                                        <td colSpan={2} width="50%">累计运行：</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={2} width="50%">晶闸管温度：{}</td>
                                        <td colSpan={2} width="50%">设备保养：</td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4}>
                                            <div style={{display:"flex",width:'350px'}} id={"alarmDiv"}>
                                                <div style={{margin:"2px 20px 0px 0px"}}>故障提示：{data.fault}</div>
                                                <div style={{overflow:"hidden",flex:"1"}}>
                                                    <Tooltip title={data.state} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                        <div className="alarm-hint-tooltip-content"><span className="cancel-bold">{data.state}</span></div>
                                                    </Tooltip>
                                                </div>
                                                <div style={{margin:"0px 3px"}}>
                                                    <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px",display:data.fault === '0-无故障'?"none":"block"}}/>)}/>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td width="25%">
                                            <div style={{display:"flex"}}>
                                                <div>一级切阻：</div>
                                                <div className="preview-tabs-content-digital" style={{background:data.acce1?"#6eb92b":"red"}}></div>
                                            </div>
                                        </td>
                                        <td width="25%">
                                            <div style={{display:"flex"}}>
                                                <div>二级切阻：</div>
                                                <div className="preview-tabs-content-digital" style={{background:data.acce2?"#6eb92b":"red"}}></div>
                                            </div>
                                        </td>
                                        <td width="25%">
                                            <div style={{display:"flex"}}>
                                                <div>三级切阻：</div>
                                                <div className="preview-tabs-content-digital" style={{background:data.acce3?"#6eb92b":"red"}}></div>
                                            </div>
                                        </td>
                                        <td width="25%">
                                            <div style={{display:"flex"}}>
                                                <div>制动输出：</div>
                                                <div className="preview-tabs-content-digital" style={{background:data.brake?"#6eb92b":"red"}}></div>
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        }
                    ]}
                />
            </div>
            <div className={"preview-tabs-head"} style={{marginTop:"10px"}}>
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size={"middle"}
                    onChange={(activeKey:string)=>{}}
                    items={[
                        {
                            label:<span className="preview-tabs-head-label">报警显示</span>,
                            key:"1",
                            children:<CardHighchartsPie param={cardData.param} seriesData={cardData.param.data?.alarmSumItems} />
                        }, {
                            label:<span className="preview-tabs-head-label">轨迹曲线</span>,
                            key:"2",
                            children:<CardHighchartsSpline tickPositions={(cardData.param.path.includes("主起升") || cardData.param.path.includes("副起升")?[0,12.5,25,37.5,50,62.5]:[0,5.5,11,16.5,21.5,27.5])} param={cardData.param} realData={[data?.currentU,data?.currentV,data?.currentW]} series={['U相电流','V相电流','W相电流']} />
                        }
                    ]}
                />
            </div>
        </>
    );
};

export default QY3ATestPreview;