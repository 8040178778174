import {Tooltip, Tabs} from 'antd';
import React, {useState} from 'react';
import '../../../css/cardPreview.css';
import {getMotionType} from "../../../utils/PublicMethodTsx";
import {alarm_hint_tooltip_css as alarmHintTooltipCss} from "../../../utils/PublicConstant";
import Icon from '@ant-design/icons';
import alarmImg from  "../../../imagers/icon/alarm.png";
import CardEquipmentTooltip from "../../../utils/module/CardEquipmentTooltip";
import CardHighchartsPie from "../../../utils/module/CardHighchartsPie";
import CardDigitalModule from "../../../utils/module/CardDigitalModule";

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const QY3ANonPreview: React.FC<CardData> = (cardData) => {
    const [data,setData] = useState({currentU:'',currentV:'',currentW:'',gearMerge:'',speed:'',state:'',
        fault:'',brakes:[],acce11:false,acce12:false,acce13:false,acce21:false,acce22:false,acce23:false,
        voltageU:"",usageState:"",devRunTime:"",voltageV:"",voltageW:""});

    const [motionType,setMotionType] = useState<boolean|null>();
    React.useEffect(() => {
        setMotionType(getMotionType(cardData.param?.mechId));
        let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        setData(newData ?? data);
    },[cardData.realData]);
    return (
        <>
            <div className={"preview-tabs-head"}>
                <Tabs defaultActiveKey="1" type="card" size={"middle"} tabBarStyle={{}}
                      items={[
                          {
                              key:"1",
                              label:<span className="preview-tabs-head-label">主用</span>,
                              children:<div className={"preview-tabs-content"}>
                                  <table className="table table-borderless">
                                      <tbody>
                                      <tr>
                                          <td colSpan={2} width="50%" className="equipment-name-background">
                                              <CardEquipmentTooltip name={"设备名称"} nameLabel={"绕线电机交直流调速器"}/>
                                          </td>
                                          <td colSpan={2} width="50%">
                                              <CardEquipmentTooltip name={"型号规格"} nameLabel={""}/>
                                          </td>
                                      </tr>
                                      <tr>
                                          <td colSpan={2} width="50%">输入档位：{data.gearMerge}</td>
                                          <td colSpan={2} width="50%">{motionType?'起升':'平移'}速度：{data.speed}</td>
                                      </tr>
                                      <tr>
                                          <td width="25%" style={{borderRight:"0px"}}>机构电流：</td>
                                          <td style={{borderRight:"0px"}}>Iu：{data.currentU}</td>
                                          <td style={{borderRight:"0px"}}>Iv：{data.currentV}</td>
                                          <td style={{borderRight:"0px"}}>Iw：{data.currentW}</td>
                                      </tr>
                                      {
                                          motionType&&<tr>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="k410" digitalValue={data.acce11}></CardDigitalModule>
                                              </td>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="k411" digitalValue={data.acce12}></CardDigitalModule>
                                              </td>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="k412" digitalValue={data.acce13}></CardDigitalModule>
                                              </td>
                                              <td width="25%">

                                              </td>
                                          </tr>
                                      }
                                      {
                                          motionType&& <tr>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="k420" digitalValue={data.acce21}></CardDigitalModule>
                                              </td>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="k421" digitalValue={data.acce22}></CardDigitalModule>
                                              </td>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="k422" digitalValue={data.acce23}></CardDigitalModule>
                                              </td>
                                              <td width="25%">
                                              </td>
                                          </tr>
                                      }
                                      {
                                          motionType&&<tr>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="制动1" digitalValue={data.brakes!=null&&data.brakes.length>0?data.brakes[0]:null}></CardDigitalModule>
                                              </td>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="制动2" digitalValue={data.brakes!=null&&data.brakes.length>1?data.brakes[1]:null}></CardDigitalModule>
                                              </td>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="制动3" digitalValue={data.brakes!=null&&data.brakes.length>2?data.brakes[2]:null}></CardDigitalModule>
                                              </td>
                                              <td width="25%">
                                                  <CardDigitalModule digitalName="制动4" digitalValue={data.brakes!=null&&data.brakes.length>3?data.brakes[3]:null}></CardDigitalModule>
                                              </td>
                                          </tr>
                                      }
                                      <tr>
                                          <td colSpan={2} width="50%">累计运行：{data.devRunTime}</td>
                                          <td colSpan={2} width="50%">设备保养：{data.usageState}</td>
                                      </tr>
                                      <tr>
                                          <td colSpan={4}>
                                              <div style={{display:"flex",width:'350px'}} id={"alarmDiv"}>
                                                  <div style={{margin:"2px 20px 0px 0px"}}>故障提示：{data.fault}</div>
                                                  <div style={{overflow:"hidden",flex:"1"}}>
                                                      <Tooltip title={data.state} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                          <div className="alarm-hint-tooltip-content"><span className="cancel-bold">{data.state}</span></div>
                                                      </Tooltip>
                                                  </div>
                                                  <div style={{margin:"0px 3px"}}>
                                                      <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px",display:data.fault === '0-无故障'?"none":"block"}}/>)}/>
                                                  </div>
                                              </div>
                                          </td>
                                      </tr>
                                      </tbody>
                                  </table>
                              </div>
                          }
                      ]}
                />
            </div>
            <div className={"preview-tabs-head"} style={{marginTop:"10px"}}>
                <Tabs defaultActiveKey="1" type="card" size={"middle"} style={{}} onChange={(activeKey:string)=>{}}
                      items={[
                          {
                              key:"1",
                              label:<span className="preview-tabs-head-label">报警显示</span>,
                              children:<CardHighchartsPie param={cardData.param} seriesData={cardData.param.data?.alarmSumItems} />
                          }/*, {
                                label:<span className="preview-tabs-head-label">轨迹曲线</span>, key:"2",
                                children:<CardHighchartsSpline tickPositions={null} realData={[data?.currentU,data?.currentV,data?.currentW]} param={cardData.param} series={['U相电流','V相电流','W相电流']} />
                            }*/
                      ]}
                />
            </div>
        </>
    );
};

export default QY3ANonPreview;