import Icon,* as IconAll from "@ant-design/icons";
import React from "react";
import {Col, message, TablePaginationConfig} from "antd";
import {FilterValue} from "antd/es/table/interface";
import {ecimpWebUserTokenKey, ecimpWebUserTokenStorageTimeKey, ecimpWebUserTokenTimeKey, tencent_maps_geolocation_key,
    tencent_maps_geolocation_referer, windowOpenMap} from "./PublicConstant";
import userImg from "../imagers/icon/user.png";
import roleImg from "../imagers/icon/role.png";
import parameterImg from "../imagers/icon/parameter.png";
import clientImg from "../imagers/icon/client.png";
import equipmentImg from "../imagers/icon/equipment.png";
import searchImg from "../imagers/icon/search.png";
import {Navigate} from "react-router-dom";
import {operationLogAdd} from "../api/OperationLogApi";
import {getEcimpV4Token} from "../api/Auth";
import CardHeadModule from "./module/CardHeadModule";

declare global {
    interface Window {
        qq: any;
        myBaseLocalOrNot:any;
    }
}
//返回antd的图标
let menuIcon = new Map([["user",userImg],["role",roleImg],["parameter",parameterImg],["client",clientImg],["equipment",equipmentImg],
    ["search",searchImg]]);
export  const getIcon = (props: { icon: string},width = "13px",height = "14px" ) => {
    if(props.icon == null || props.icon.length == 0){
        return '';
    }else{
        if(menuIcon.has(props.icon)){
            return  <Icon component={() => (<img src={menuIcon.get(props.icon)} style={{width:width,height:height}}/>)}/>;
        }else{
            const { icon } = props;
            const antIcon: { [key: string]: any } = IconAll;
            return React.createElement(antIcon[icon]);
        }
    }
};
//返回树形结构中某个子节点的所有父节点某个树形的数据
export function getParent(array: Array<object>, key: string|number, attribute:string | null): Array<string|number> {
    let result: Array<string|number> = [];
    let toToo = true;
    const catchData = (array: Array<object>, key: string|number) => {
        array.forEach((item: any) => {
            if (!toToo) return;
            result.push(String(item[attribute==null?'id':attribute]));
            if (item['id'] === key) {
                toToo = false;
            } else if (item['children']) {
                catchData(item['children'], key);
            } else {
                result.pop();
            }
        });
        toToo && result.pop();
    };
    catchData(array, key);
    return result;
}
//操作日志记录
export const operationLogAddMethod = (data:any)=>{
    operationLogAdd({data: data}).then(()=>{}).finally(()=>{});
}
//判断是否已经登录
export const judgeIsLogin = ({ children }: { children: JSX.Element })=>{
    let ecimpWebUserToken = localStorage.getItem(ecimpWebUserTokenKey);
    let ecimpWebUserTokenTime = localStorage.getItem(ecimpWebUserTokenTimeKey);
    let ecimpWebUserTokenStorageTime = localStorage.getItem(ecimpWebUserTokenStorageTimeKey);
    if((ecimpWebUserToken === null || ecimpWebUserToken.trim() === "") || (new Date().getTime()-Number(ecimpWebUserTokenTime) > Number(ecimpWebUserTokenStorageTime))){
        return <Navigate to="/login"/>;
    }
    return children;
}
function getCardObject(item:any,viewName:string){
    if (item.name === 'qy3'){
        if(item.displayName === "调压调速")
            return require('../view/card/type/QY3APreview').default;
        else if(item.displayName === "制动系统")
            return require('../view/card/type/QY3ABrakingPreview').default;
        else if(item.displayName === "传动系统")
            return require('../view/card/type/QY3ADrivePreview').default;
        else if(item.displayName === "变频调速")
            return require('../view/card/type/FrequencyControlPreview').default;
    } else if (item.name === 'qy3_test'){
        if(item.displayName === "调压调速")
            return require('../view/card/type/QY3ATestPreview').default;
    } else if (item.name === 'qy3_demo'){
        if(item.displayName === "调压调速")
            return require('../view/card/type/QY3ADemoPreview').default;
    }else if (item.name == 'dv02')
        return require('../view/card/type/QRL1'+viewName).default;
    else if (item.name == 'inverter')
        return require('../view/card/type/FrequencyControlPreview').default;
    else if (item.name == 'jsr2t')
        return require('../view/card/type/JSR2TPreview').default;
    else if (item.name == 'conventional')
        return require('../view/card/type/ConventionalPreview').default;
    else if (item.name == 'crane')
        return require('../view/card/type/CranePreview').default;
    else if (item.name == 'qy3-non-config')
        return require('../view/card/type/QY3ANonPreview').default;
    else
        return require('../view/card/type/ErrorPreview').default;
}
//返回添加卡片页面子卡片
export function getCard(item:any,exchangeStart:any,exchangeDrop:any,index:any,
                        setSonIndex:any,sonList:any,setSonList:any,sonIndex:any,type:any,viewName:string): any {
    let Card = getCardObject(item,viewName);
    if(Card != null)
        return <Col
            key={item.id}
            draggable="true"
            onDragOver={event => event.preventDefault()}
            onDragStart={event => exchangeStart(event, index, setSonIndex)}
            onDrop={(de) => exchangeDrop(de, index, sonList, setSonList, sonIndex)}
            className="gutter-row" xxl={8} xl={12} md={24}>
            <Card viewTypeList={sonList}
                  setViewTypeList={setSonList} param={item} type={type}/></Col>
}

//返回浏览卡片页面子卡片
export function getPreviewCard(item:any,item2:any,realData:any,viewName:string,gridXXL:number, setItems:any,setActiveKey:any,itemsRef:any): any {
    let Card = getCardObject(item2,viewName);
    return <Col
        key={item2.id}
        style={{height:'100%'}}
        className="gutter-row"
        xxl={gridXXL} xl={12} lg={24} md={24}>
        <Card fatherId={item.id} param={item2} realData={realData} setActiveKey={setActiveKey} setItems={setItems} itemsRef={itemsRef}/></Col>
}
//返回浏览卡片页面子卡片 子元素自行获取实时数据
export function getPreviewCard2(item:any,realData:any,viewName:string,gridXXL:number,
                                setItems:any,setActiveKey:any,items:any,
                                exchangeStart:any,exchangeDrop:any,index:any,setFront:any,viewTypeList:any,setViewTypeList:any,front:any,viewOperate:any): any {
    let Card = getCardObject(item,viewName);
    if(Card != null)
        return <Col
            key={item.id}
            draggable="true"
            onDragOver={event => event.preventDefault()}
            onDragStart={event => exchangeStart(event, index, setFront)}
            onDrop={(de) => exchangeDrop(de, index, viewTypeList, setViewTypeList, front)}
            style={{height:'100%',textAlign:"center"}}
            className="gutter-row"
            xxl={gridXXL} xl={8} lg={12} md={24}>
            <CardHeadModule cardPreview={Card} viewOperate={viewOperate} viewTypeList={viewTypeList} setViewTypeList={setViewTypeList} param={item}
                            realData={realData} setActiveKey={setActiveKey} setItems={setItems} items={items}/>
{/*
            <Card viewOperate={viewOperate} viewTypeList={viewTypeList} setViewTypeList={setViewTypeList} param={item} realData={realData} setActiveKey={setActiveKey} setItems={setItems} items={items}/>
*/}

        </Col>
}
//返回浏览卡片页面子卡片手机端
export function getPreviewCardApp(item:any,realData:any,viewName:string){
    let Card = getCardObject(item,viewName);
    return <Col
        key={item.id}
        style={{paddingBottom:"10px"}}
        span={24}>
        <Card param={item} realData={realData}/></Col>
}
//判断是否是手机端
export function judgeEquipment(){
    let userAgentInfo = navigator.userAgent;
    let agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let getArr = agents.filter(i => userAgentInfo.includes(i));
    return getArr.length ? true : false;
}
//通过腾讯地图获取用户当前地理位置
export function getUserLocation(timeout:Number | null,failTipFlag:boolean | null,positionData:any,method:any | null){
    let geolocation = new window.qq.maps.Geolocation(tencent_maps_geolocation_key, tencent_maps_geolocation_referer);
    let options = {timeout: timeout ?? 9000,failTipFlag:failTipFlag ?? false};
    geolocation.getLocation((position:any)=>{
        let data = JSON.stringify(position);
        positionData(data);
        if(method != null)
            method(data);
    }, ()=>{
        positionData("获取位置信息失败");
        if(method != null)
            method("获取位置信息失败");
    }, options);
}

//获取设备类型
export function getSystemType() {
    return navigator.userAgent.toLowerCase();
}
//返回内容在html中长度
export const getContentPx = (content:string,fontSize:string)=>{
    const dom = document.createElement('div');
    dom.style.display = 'inline-block';
    dom.style.fontSize = `${fontSize}px`;
    dom.textContent = content;
    document.body.appendChild(dom);
    const width = dom.clientWidth;
    document.body.removeChild(dom);
    return width;
}
//返回卡片标题路径
export const getCardHeadPath = (path:string,fontSize:string)=>{
    let width = getContentPx(path,fontSize);
    if(width > 200){
        let pathArray =  path.split("\\");
        let newPath = "\\"+pathArray[pathArray.length-1],frontPath = newPath;
        for(let i=pathArray.length-2;i>=0;i--){
            newPath = "\\"+pathArray[i]+newPath;
            width = getContentPx(newPath,fontSize);
            if(width > 200){
                return "..."+frontPath;
            }
            frontPath = newPath;
        }
    }
    return path;
}
//返回最上层企业名称
// @ts-ignore
export const getTopEnterpriseName = (list:Array<any>,value:string)=>{
    let enterprise = list.find((item:any)=>item.id === value);
    if(enterprise != null && enterprise.fatherId !== "0" && value !== enterprise.fatherId)
        return getTopEnterpriseName(list,enterprise.fatherId);
    return enterprise?.name;
}
// @ts-ignore
export const getTopEnterpriseId = (list:Array<any>,value:string)=>{
    let enterprise = list.find((item:any)=>item.id === value);
    if(enterprise != null && enterprise.fatherId !== "0" && value !== enterprise.fatherId)
        return getTopEnterpriseId(list,enterprise.fatherId);
    return enterprise?.id;
}
//卡片信号灯颜色返回
export const getCardDigital = (value:boolean | null)=>{
    if(value === true){
        return "#6eb92b";
    }else if(value === false)
        return "red";
    return "gray";
}
//保养信号灯颜色返回
export const getCardDigitalMaintain = (value:string)=>{
    if(value == '正常'){
        return "#6eb92b";
    }else if(value === '保养')
        return "orange";
    else if(value === '更换')
        return "red";
    return "gray";
}
//曲线值字符串返回数字
export const getSplineValue = (value:string)=>{
    const match = (value+"").match(/^\d+(\.\d+)?/);
    value = match ? match[0] : '';
    if(value != null && value != ""){
        return Number(value);
    }else{
        return 0;
    }
}
//跳转v5页面
export const jumpEcimp5 = (craneId:string,craneUrl:string,addItems:any,label:string,hierarchy:string,key:string,url:string)=>{
    getEcimpV4Token({craneId:craneId}).then(res=> {
        addItems(key,{
            label: label, hierarchy: hierarchy??'',
            children: <div>
                <iframe className="iframe-details"
                        style={{
                            height: (document.getElementById("controlConsoleContent")!.offsetHeight - 15) + "px",
                            width: (document.getElementById("controlConsoleContent")!.offsetWidth - 10) + "px"
                        }}
                        src={craneUrl + "/ecimpweb/link?url=" + encodeURIComponent(url) + "&t=" + res.data.data.items[0].token}></iframe>
            </div>,
            key: key, path: ''
        });
    });
}
//设置css中root属性值
export const setCssSelectorRoot = (selectors:string | null,key:string,value:string) => {
    let root=document.querySelector(selectors==null?":root":selectors);
    if(root !=null){
        (root as any).style.setProperty(key,value);
    }
}
//返回css中root属性值
export const getCssSelectorRoot = (selectors:string | null,key:string) => {
    let root = document.querySelector(selectors==null?":root":selectors);
    if(root !=null){
        return getComputedStyle(root).getPropertyValue(key)
    }
    return "";
}
export const downloadFile = (fileName:string,data:any) => {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const fileUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = fileUrl;
    a.target = '_blank';
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
export const getMotionTag = (motion:any)=>{
    if(motion == 2 || motion == 3 || motion == 4 || motion == 8){
        return "hst3";
    }else if(motion == 5 || motion == 6 || motion == 7){
        return "trv3";
    }else
        return "";
}
export const getMotionName = (motion:number)=>{
    if(motion == 2){
        return "主起升";
    }if(motion == 3){
        return "副起升";
    }if(motion == 4){
        return "副起升";
    }if(motion == 5){
        return "大车";
    }if(motion == 6){
        return "主小车";
    }if(motion == 7){
        return "副小车";
    }if(motion == 12){
        return "整车";
    }
    return '';
}
export const getMotionType = (motion:any)=>{
    if(motion == 2 || motion == 3 || motion == 4 || motion == 8){
        return true;
    }else if(motion == 5 || motion == 6 || motion == 7){
        return false;
    }else
        return null;
}
export const getEnterpriseAttribute = (value:string) => {
    value = value !=null?value+"":"";
    const mapping = {'1': '业主', '2': '主机厂', '3': '制造商', '4': '设备商'};
    const valueString = value.split('');
    // @ts-ignore
    const translated = valueString.map(val => mapping[val] || '').join(',');
    return translated.endsWith(',') ? translated.slice(0, -1) : translated;
}

//跳转工作站
export const skipCrane = (craneId:string,url:string,craneName:string) => {
    if(window.myBaseLocalOrNot.localOrNot==2){
        windowOpenMap.set(url, window.open(url +"/main"));
    }else{
        getEcimpV4Token({craneId: craneId}).then(res => {
            let win = windowOpenMap.get(url);
            if (win != null && win.opener != null) {
                win.focus();
            } else {
                let newUrl = url + (craneName === "共久测试台" ? "/ecimpweb/link?url=" : "/ecimpweb-link?url=") + encodeURIComponent(craneName === "共久测试台" ? "/main" : "/index") + "&t=" + res.data.data.items[0].token;
                windowOpenMap.set(url, window.open(newUrl, url));
            }
        }).catch(() => { message.success({content:'跳转失败',duration:4,style:{marginTop:"50vh"}});});
    }
}
//详情跳转页面返回路径
export const getCardDetailsPage = (name:string) => {
    if(window.myBaseLocalOrNot.localOrNot==2){
        return "";
    }
    if(name == "qy3")
        return "pressureRegulatingPage";
    else if(name == "inverter")
        return "inverterPage";
    else if(name == "qy3_test")
        return "dataChart";
    else if(name == "jsr2t")
        return "jsr2Page";
    else if(name == "conventional")
        return "conventionalPage";
    else if(name == "qy3-non-config")
        return "pressureRegulatingNonPage";
    return "";
}
//公共搜索按钮方法
export const searchPublic = (tableParams:any,setTableParams:any,fetchData:any) => {
    if(tableParams.pagination?.current != 1)
        setTableParams({pagination: {...tableParams.pagination,current:1}});
    else
        fetchData();
    return null;
}
//公共重置按钮方法
export const resetPublic = (form:any,formFields:any) => {
    form.setFieldsValue(JSON.parse(JSON.stringify(formFields)));
}
//公共table分页点击事件
export const handleTableChangePublic = (pagination: TablePaginationConfig, filters: Record<string, FilterValue | null>, sorter: any,setTableParams:any) => {
    setTableParams({pagination, filters, ...sorter,});
}
