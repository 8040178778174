import Axios from 'axios';
import {Button, message} from 'antd';
import React from "react";
import {judgeEquipment} from "./PublicMethodTsx";
import {ecimpWebUserTokenKey, ecimpWebUserTokenTimeKey} from "./PublicConstant";

declare global {
    interface Window {
        myBaseURL: any;
    }
}


Axios.defaults.baseURL = window.myBaseURL.url;
/*Axios.defaults.baseURL = "http://192.168.21.205:9010";*/
/*Axios.defaults.baseURL = "http://47.101.214.110:9011";*/

Axios.defaults.timeout = 10000;
/*Axios.create({})*/
Axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
Axios.defaults.headers.put['Content-Type'] = 'application/json;charset=utf-8';
//axios.defaults.headers['authorization'] = localStorage.getItem("eectrl_operations_token");
Axios.interceptors.request.use((config) => {
    localStorage.setItem(ecimpWebUserTokenTimeKey,new Date().getTime().toString());
    config.headers = {
        "Authorization":  "EcimpWebUserToken "+localStorage.getItem(ecimpWebUserTokenKey),
        "Content-Type": "application/json;charset=utf-8"
    }
    /*if (config.method === 'post') {
         config.data = qs.stringify(config.data);
     }*/
    return config;
});
// Add a response interceptor
Axios.interceptors.response.use(
    (response) => {
        if(response.data.code == '401'){//Unauthorized

        }else if(response.data.code == '403'){//Forbidden

        }else if(response.data.code == '404'){//Not Found

        }
        if(response.data.msg == 'error'){
            message.warning({content:response.data.msgContent,duration:4,style:{marginTop:"50vh"}});
        }else if(response.data.msg == 'warn'){
            message.warning({content:response.data.msgContent,duration:4,style:{marginTop:"50vh"}});
        }
        return response;
    },
    (error) => {
        //message.error({content:'系统错误',duration:4,style:{marginTop:"50vh"}});
        if(error?.response?.status == 0){
            message.warning({content:"服务器未响应！",duration:4,style:{marginTop:"50vh"}});
        } else if(error?.response?.status == 401){
            //message.warning({content:"请重新登录！",duration:2,style:{marginTop:"50vh"}});
            localStorage.setItem(ecimpWebUserTokenKey,"");
            let href = "";
            if(judgeEquipment()){
                const urlParams = new URL(window.location.href);
                const pathname = urlParams?.pathname;
                if(pathname == "/fileApp"){

                }
            }else{
                href = "/login";
            }
            if(href != "")
                setTimeout(()=> {
                    //window.location.reload();
                    document.location.href = href;
                },100);
        }else if(error?.response?.status == 403){
            message.warning({content:"没有权限！",duration:4,style:{marginTop:"50vh"}});
        }else if(error?.response?.status == 404){
            message.warning({content:"请求404！",duration:4,style:{marginTop:"50vh"}});
        }else if(error?.response?.status == 500){
            message.warning({content:"服务器异常！",duration:4,style:{marginTop:"50vh"}});
        }else
            console.log(error);
        return Promise.reject(error);
    }
);
export let baseURL = Axios.defaults.baseURL;