import React from 'react';
import {Tooltip} from "antd";

interface CardData{
    name:string;
    nameLabel:string;
}
const CardEquipmentTooltip: React.FC<CardData> = (cardData) => {
    return (
        <>
            <div style={{display:"flex"}}>
                <div className="equipment-name">{cardData.name}：</div>
                <div style={{flex:"1",overflow:"hidden"}}>
                    <Tooltip title={cardData.nameLabel} color="#C9CACA" overlayInnerStyle={{color:"#282829",marginTop:"-8px",maxWidth:"200px"}} placement="bottom">
                        <div className="equipment-name-ellipsis">
                            <span>{cardData.nameLabel}</span>
                        </div>
                    </Tooltip>
                </div>
            </div>
        </>
    );
};

export default CardEquipmentTooltip;