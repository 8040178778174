import React from 'react';
import {Card, Tooltip} from "antd";
import CardHeadExtra from "./CardHeadExtra";
import {getCardHeadPath} from "../PublicMethodTsx";
import '../../css/cardPreview.css';

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
    cardPreview:any;
}
const CardHeadModule: React.FC<CardData> = (cardData) => {
    return (
        <>
            <Card
                hoverable={false}
                className="card-extend"
                style={{background:"var(--cardPreviewBackgroundColor)"}}
                headStyle={{background:"#ffe100",borderRadius:"6px 6px 0px 0px",minHeight:"43px",height:"43px"}}
                bodyStyle={{height:"100%",padding:"16px 8px"}}
                extra={<CardHeadExtra setItems={cardData.setItems} setActiveKey={cardData.setActiveKey} items={cardData.items} param={cardData.param}
                                      viewOperate={cardData.viewOperate} viewTypeList={cardData.viewTypeList} setViewTypeList={cardData.setViewTypeList}/>}
                title={
                    <div className={"head-card-title"}>
                        <div className={"head-card-title-label"}>
                            <div style={{lineHeight:"19px",marginTop:"-1px"}}>{cardData.param.displayName}</div>
                        </div>
                        <div style={{flex:"1",textAlign:"left",marginLeft:"2px"}}>
                            <Tooltip title={cardData.param.pathAbbr} color="#C9CACA" overlayInnerStyle={{color:"#282829",marginTop:"-8px",width:"100%"}} placement="bottom">
                                <div style={{fontSize:"11px"}}>{getCardHeadPath(cardData.param.pathAbbr,"11")}</div>
                            </Tooltip>
                        </div>
                    </div>
                }
                type="inner"
            >
                <cardData.cardPreview viewOperate={cardData.viewOperate} viewTypeList={cardData.viewTypeList} setViewTypeList={cardData.setViewTypeList} param={cardData.param}
                      realData={cardData.realData} setActiveKey={cardData.setActiveKey} setItems={cardData.setItems} items={cardData.items}/>
            </Card>
        </>

    );
};
export default CardHeadModule;