import {Card, Tooltip, Tabs} from 'antd';
import React, {useState} from 'react';
import Icon from "@ant-design/icons";
import '../../../css/cardPreview.css';
import {getCardHeadPath} from "../../../utils/PublicMethodTsx";
import {alarm_hint_tooltip_css as alarmHintTooltipCss} from "../../../utils/PublicConstant";
import alarmImg from "../../../imagers/icon/alarm.png";
import CardHeadExtra from "../../../utils/module/CardHeadExtra";
import CardEquipmentTooltip from "../../../utils/module/CardEquipmentTooltip";

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const QY3ADrivePreview: React.FC<CardData> = (cardData) => {
    const [data,setData] = useState({currentU:'',currentV:'',currentW:'',gearMerge:'',speed:'',state:'',fault:''});
    React.useEffect(() => {
        let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        setData(newData ?? data);
    },[cardData.realData]);
    React.useEffect(() => {

    },[]);
    return (
        <>
            <Card
                hoverable={false}
                className="card-extend"
                style={{borderRadius:"6px",border:"0px",width:"376px",margin:"auto",height:"552px",background:"var(--cardPreviewBackgroundColor)"}}
                headStyle={{background:"#ffe100",borderRadius:"6px 6px 0px 0px",minHeight:"43px",height:"43px"}}
                bodyStyle={{height:"100%",padding:"16px"}}
                extra={<CardHeadExtra setItems={cardData.setItems} setActiveKey={cardData.setActiveKey} items={cardData.items} param={cardData.param} viewOperate={cardData.viewOperate} viewTypeList={cardData.viewTypeList} setViewTypeList={cardData.setViewTypeList}/>}
                title={
                    <div className={"head-card-title"}>
                        <div className={"head-card-title-label"}>
                            <div style={{marginTop:"-2px"}}>{cardData.param.displayName}</div>
                        </div>
                        <div style={{flex:"1",textAlign:"left",marginLeft:"2px"}}>
                            <Tooltip title={cardData.param.path} color="#C9CACA" overlayInnerStyle={{color:"#282829",marginTop:"-8px",width:"100%"}} placement="bottom">
                                <div style={{fontSize:"11px"}}>{getCardHeadPath(cardData.param.path,"11")}</div>
                            </Tooltip>
                        </div>
                    </div>
                }
                type="inner"
            >
                <div className={"preview-tabs-content"}>
                    <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                        <tbody>
                            <tr>
                                <td colSpan={2} width="50%" className="equipment-name-background">
                                    <CardEquipmentTooltip name={"设备名称"} nameLabel={""}/>
                                </td>
                                <td colSpan={2} width="50%">
                                    <CardEquipmentTooltip name={"规格型号"} nameLabel={""}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} width="50%">累计运行：{}</td>
                                <td colSpan={2} width="50%">实测温度：</td>
                            </tr>
                            <tr>
                                <td colSpan={2} width="50%">实测驱动：{}</td>
                                <td colSpan={2} width="50%">设备保养：</td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <div style={{display:"flex"}} id={"alarmDiv"}>
                                        <div style={{margin:"2px 20px 0px 0px"}}>故障提示：无速度反馈</div>
                                        <div style={{overflow:"hidden",flex:"1"}}>
                                            <Tooltip title={"主令启动是无速度反馈或运动中速度反馈消失"} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                <div className="alarm-hint-tooltip-content"><span className="cancel-bold">主令启动是无速度反馈或运动中速度反馈消失</span></div>
                                            </Tooltip>
                                        </div>
                                        <div style={{margin:"0px 3px"}}>
                                            <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px"}}/>)}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={"preview-tabs-head"} style={{marginTop:"10px"}}>
                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                        size={"middle"}
                        tabBarStyle={{}}
                        items={[
                            {
                                label:<span className="preview-tabs-head-label">1号电机</span>,
                                key:"1",
                                children:<div className={"preview-tabs-content"}>
                                    <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2} width="50%" className="equipment-name-background">
                                                <CardEquipmentTooltip name={"设备名称"} nameLabel={"绕线电机"}/>
                                            </td>
                                            <td colSpan={2} width="50%">
                                                <CardEquipmentTooltip name={"规格型号"} nameLabel={""}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">电机功率：{}</td>
                                            <td colSpan={2} width="50%">累计运行：</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">实测温度：{}</td>
                                            <td colSpan={2} width="50%">实测驱动：</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">设备保养：{}</td>
                                            <td colSpan={2} width="50%"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                <div style={{display:"flex"}} id={"alarmDiv"}>
                                                    <div style={{margin:"2px 20px 0px 0px"}}>故障提示：无速度反馈</div>
                                                    <div style={{overflow:"hidden",flex:"1"}}>
                                                        <Tooltip title={"主令启动是无速度反馈或运动中速度反馈消失"} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                            <div className="alarm-hint-tooltip-content"><span className="cancel-bold">主令启动是无速度反馈或运动中速度反馈消失</span></div>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{margin:"0px 3px"}}>
                                                        <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px"}}/>)}/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ borderTop:"2px solid #a3a3a3"}}>
                                            <td colSpan={2} width="50%" className="equipment-name-background">
                                                <CardEquipmentTooltip name={"设备名称"} nameLabel={"超速开关"}/>
                                            </td>
                                            <td colSpan={2} width="50%">
                                                <CardEquipmentTooltip name={"规格型号"} nameLabel={""}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">电机功率：{}</td>
                                            <td colSpan={2} width="50%">累计运行：</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">设备保养：{}</td>
                                            <td colSpan={2} width="50%"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                <div style={{display:"flex"}} id={"alarmDiv"}>
                                                    <div style={{margin:"2px 20px 0px 0px"}}>故障提示：无速度反馈</div>
                                                    <div style={{overflow:"hidden",flex:"1"}}>
                                                        <Tooltip title={"主令启动是无速度反馈或运动中速度反馈消失"} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                            <div className="alarm-hint-tooltip-content"><span className="cancel-bold">主令启动是无速度反馈或运动中速度反馈消失</span></div>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{margin:"0px 3px"}}>
                                                        <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px"}}/>)}/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr style={{ borderTop:"2px solid #a3a3a3"}}>
                                            <td colSpan={2} width="50%" className="equipment-name-background">
                                                <CardEquipmentTooltip name={"设备名称"} nameLabel={"智能高位限速器"}/>
                                            </td>
                                            <td colSpan={2} width="50%">
                                                <CardEquipmentTooltip name={"规格型号"} nameLabel={""}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">电机功率：{}</td>
                                            <td colSpan={2} width="50%">累计运行：</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">设备保养：{}</td>
                                            <td colSpan={2} width="50%"></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                <div style={{display:"flex"}} id={"alarmDiv"}>
                                                    <div style={{margin:"2px 20px 0px 0px"}}>故障提示：无速度反馈</div>
                                                    <div style={{overflow:"hidden",flex:"1"}}>
                                                        <Tooltip title={"主令启动是无速度反馈或运动中速度反馈消失"} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                            <div className="alarm-hint-tooltip-content"><span className="cancel-bold">主令启动是无速度反馈或运动中速度反馈消失</span></div>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{margin:"0px 3px"}}>
                                                        <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px"}}/>)}/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            },{
                                label:<span className="preview-tabs-head-label">2号电机</span>,
                                key:"2",
                                children:<div className={"preview-tabs-content"}>
                                    <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                                        <tbody>
                                            <tr>
                                                <td colSpan={2} width="50%" className="equipment-name-background">
                                                    <CardEquipmentTooltip name={"设备名称"} nameLabel={"智能高位限速器"}/>
                                                </td>
                                                <td colSpan={2} width="50%">
                                                    <CardEquipmentTooltip name={"规格型号"} nameLabel={""}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} width="50%">动作次数：{}</td>
                                                <td colSpan={2} width="50%">累计运行：</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} width="50%">制动距离：{}</td>
                                                <td colSpan={2} width="50%">设备保养：</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div style={{display:"flex"}} id={"alarmDiv"}>
                                                        <div style={{margin:"2px 20px 0px 0px"}}>故障提示：无速度反馈</div>
                                                        <div style={{overflow:"hidden",flex:"1"}}>
                                                            <Tooltip title={"主令启动是无速度反馈或运动中速度反馈消失"} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                                <div className="alarm-hint-tooltip-content"><span className="cancel-bold">主令启动是无速度反馈或运动中速度反馈消失</span></div>
                                                            </Tooltip>
                                                        </div>
                                                        <div style={{margin:"0px 3px"}}>
                                                            <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px"}}/>)}/>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr style={{ borderTop:"2px solid #a3a3a3"}}>
                                                <td colSpan={2} width="50%" className="equipment-name-background">
                                                    <CardEquipmentTooltip name={"设备名称"} nameLabel={"智能高位限速器"}/>
                                                </td>
                                                <td colSpan={2} width="50%">
                                                    <CardEquipmentTooltip name={"规格型号"} nameLabel={""}/>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} width="50%">动作次数：{}</td>
                                                <td colSpan={2} width="50%">累计运行：</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={2} width="50%">制动距离：{}</td>
                                                <td colSpan={2} width="50%">设备保养：</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4}>
                                                    <div style={{display:"flex"}} id={"alarmDiv"}>
                                                        <div style={{margin:"2px 20px 0px 0px"}}>故障提示：无速度反馈</div>
                                                        <div style={{overflow:"hidden",flex:"1"}}>
                                                            <Tooltip title={"主令启动是无速度反馈或运动中速度反馈消失"} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                                <div className="alarm-hint-tooltip-content"><span className="cancel-bold">主令启动是无速度反馈或运动中速度反馈消失</span></div>
                                                            </Tooltip>
                                                        </div>
                                                        <div style={{margin:"0px 3px"}}>
                                                            <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px"}}/>)}/>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                        ]}
                    />
                </div>
            </Card>
        </>
    );
};

export default QY3ADrivePreview;