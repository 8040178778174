import React,{lazy,Suspense} from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom'
import reportWebVitals from './reportWebVitals';
import {judgeIsLogin as JudgeIsLogin} from "./utils/PublicMethodTsx";
import './utils/axios';
import './css/index.css';
import 'antd/dist/antd.min.css';
import 'antd/dist/antd.variable.min.css';
import { ConfigProvider } from "antd"
import zhCN from "antd/lib/locale/zh_CN"

declare global {
    interface Window {
        myBaseLocalOrNot:any;
    }
}

const Login = window.myBaseLocalOrNot.localOrNot==1?lazy(()=>import("./view/Login")):lazy(()=>import("./view/Login2"));
const Main = lazy(()=>import("./view/Main"));
const DocApp = lazy(()=>import("./view/download/DocApp"));
const MainApp = lazy(()=>import("./view/app/MainApp"));
const RegistrationApp = lazy(()=>import("./view/app/RegistrationApp"));
const Registration = lazy(()=>import("./view/Registration"));
const WeChatLogin = lazy(()=>import("./view/app/WeChatLogin"));

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <ConfigProvider locale={zhCN} componentSize={"middle"}>
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Suspense fallback={<div></div>}><Login/></Suspense>}></Route>
                <Route path="/main"  element={<JudgeIsLogin><Suspense fallback={<div></div>}><Main /></Suspense></JudgeIsLogin>}></Route>
                <Route path="/registration" element={<Suspense fallback={<div></div>}><Registration/></Suspense>}></Route>
                <Route path="/registrationApp" element={<Suspense fallback={<div></div>}><RegistrationApp/></Suspense>}></Route>
                <Route path="/mainApp" element={<Suspense fallback={<div></div>}><MainApp/></Suspense>}></Route>
                <Route path="/docApp" element={<Suspense fallback={<div></div>}><DocApp /></Suspense>}></Route>
                <Route path="/weChatLogin" element={<Suspense fallback={<div></div>}><WeChatLogin /></Suspense>}></Route>
                <Route path="/" element={<Navigate to="/main"/>}></Route>
            </Routes>
        </BrowserRouter>
    </ConfigProvider>
);
//全局样式配置
/*ConfigProvider.config({
    theme: {
        primaryColor: '#A52A2A',
        errorColor: '#faad14',
        warningColor: '#A52A2A',
        successColor: '#A52A2A',
        infoColor: '#A52A2A',
    }
});*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
