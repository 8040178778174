import React, {useRef, useState} from 'react';
import Icon from '@ant-design/icons';
import infoImg from  "../../imagers/icon/info.png";
import cardCloseImg from  "../../imagers/icon/cardClose.png";
import {message} from "antd";
import {getEcimpV4Token} from "../../api/Auth";
import {windowOpenMap} from "../PublicConstant";
import {getCardDetailsPage, skipCrane} from "../PublicMethodTsx";

interface CardData{
    param:any;
    viewOperate:any;
    viewTypeList:any;
    setViewTypeList:any;
    items:any;
    setItems:any;
    setActiveKey:any;
}
const CardHeadExtra: React.FC<CardData> = (cardData) => {
    return (
        <>
            <div style={{display:"flex",marginBottom:"5px"}}>
                <div className={"head-card"} onClick={()=>{
                    let page = getCardDetailsPage(cardData.param.name);
                    if(page !== ""){
                        getEcimpV4Token({craneId:cardData.param.craneId}).then(res=>{
                            let item = (cardData.items as Array<any>).find(pane => pane.key === (cardData.param.id+"Details"));
                            localStorage.setItem("cardPath",cardData.param.path);
                            if(item == null && cardData.param.craneUrl != null && cardData.param.craneUrl.trim() != ""){
                                cardData.setItems([...cardData.items, { label: `${cardData.param.displayName}`,hierarchy:cardData.param.path,
                                    children:<div ><iframe className="iframe-details"
                                                           style={{height:(document.getElementById("controlConsoleContent")!.offsetHeight-15)+"px",
                                                               width:(document.getElementById("controlConsoleContent")!.offsetWidth-10)+"px"}}
                                                           src={cardData.param.craneUrl+"/ecimpweb/link?url="+encodeURIComponent("/operations/"+page+"?motion="+cardData.param.mechId+"&path="+encodeURIComponent(cardData.param.path))
                                                               +"&t="+res.data.data.items[0].token}></iframe></div>,
                                    key:cardData.param.id+"Details", path:'./card/type/QY3ADetails'}]);
                            }
                            cardData.setActiveKey(cardData.param.id+"Details");
                        }).catch(()=>{});
                       /* let item = (cardData.items as Array<any>).find(pane => pane.key === (cardData.param.id+"Details"));
                        if(item == null && cardData.param.craneUrl != null && cardData.param.craneUrl.trim() != ""){
                            cardData.setItems([...cardData.items, { label: `${cardData.param.displayName}`,hierarchy:cardData.param.path,
                                children:<div ><iframe className="iframe-details"
                                                       style={{height:(document.getElementById("controlConsoleContent")!.offsetHeight-15)+"px",
                                                       width:(document.getElementById("controlConsoleContent")!.offsetWidth-15)+"px"}}
                                                       src={'http://192.168.21.14:8080/operations/dataChart?motion=2'}></iframe></div>,
                                key:cardData.param.id+"Details", path:'./card/type/QY3ADetails'}]);
                        }
                        cardData.setActiveKey(cardData.param.id+"Details");*/
                    }else{
                        if(cardData.param.name == "crane"){
                            let win = windowOpenMap.get(cardData.param.craneUrl);
                            if(win != null && win.opener != null){
                                win.focus();
                            }else {
                                skipCrane(cardData.param.craneId,cardData.param.craneUrl,cardData.param.dispname);
                            }
                        }
                        else
                        message.warning({content:"无详情！",duration:3,style:{marginTop:"50vh"}});
                    }
                }} >
                    <div>
                        <Icon className={'head-card-icon'} component={() => (<img src={infoImg} style={{width:"10px",height:"10px",margin:"0 0 10px"}}/>)}/>
                    </div>
                </div>
                <div className={"head-card"} onClick={()=>{
                    const newList = cardData.viewTypeList.filter((item:any) => item.id !== cardData.param.id);
                    cardData.viewOperate("delete",newList);
                    cardData.setViewTypeList(newList);
                }}>
                    <div>
                        <Icon component={() => (<img src={cardCloseImg} style={{width:"10px",height:"10px",marginBottom:"10px"}}/>)}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CardHeadExtra;