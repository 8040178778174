import {Tabs} from 'antd';
import React, {useState} from 'react';
import '../../../css/cardPreview.css';
import {getCardDigital} from "../../../utils/PublicMethodTsx";
import CardEquipmentTooltip from "../../../utils/module/CardEquipmentTooltip";
import CardHighchartsPie from "../../../utils/module/CardHighchartsPie";
import CardDigitalModule from "../../../utils/module/CardDigitalModule";

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const JSR2TPreview: React.FC<CardData> = (cardData) => {
    const [realItems,setRealItems] = useState<any[]>([]);
    const [trackRealData,setTrackRealData] = useState<any[]>([]);
    const [trackSeries,setTrackSeries] = useState<any[]>([]);

    React.useEffect(() => {
        let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        if(newData != null && newData.devs.length > 0) {
            let devsList = new Array();
            for (let i = 0; i < newData.devs.length; i++) {
                let data = newData.devs[i];
                devsList.push(
                    {
                        label:<span className="preview-tabs-head-label">{data.name}</span>,
                        key:"1",
                        children:<div className={"preview-tabs-content"}>
                            <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                                <tbody>
                                <tr>
                                    <td colSpan={2} width="50%" className="equipment-name-background"><CardEquipmentTooltip name={"设备名称"} nameLabel={data?.name}/></td>
                                    <td colSpan={2} width="50%"><CardEquipmentTooltip name={"型号规格"} nameLabel={""}/></td>
                                </tr>
                                <tr>
                                    <td width="25%">输入命令：</td>
                                    <td width="25%">
                                        <CardDigitalModule digitalName="正向" digitalValue={data?.forwardCmd}></CardDigitalModule>
                                    </td>
                                    <td width="25%">
                                        <CardDigitalModule digitalName="反向" digitalValue={data?.reverseCmd}></CardDigitalModule>
                                    </td>
                                    <td width="25%">
                                        <CardDigitalModule digitalName="制动输出" digitalValue={data?.generalOutput}></CardDigitalModule>
                                    </td>
                                </tr>
                                <tr>
                                    <td width="25%" style={{borderRight:"0px"}}>定子电压：</td>
                                    <td style={{borderRight:"0px"}}>UV：{data?.voltageUv}</td>
                                    <td style={{borderRight:"0px"}}>VW：{data?.voltageVw}</td>
                                    <td style={{borderRight:"0px"}}>WU：{data?.voltageWu}</td>
                                </tr>
                                <tr>
                                    <td width="25%" style={{borderRight:"0px"}}>定子电流：</td>
                                    <td style={{borderRight:"0px"}}>Iu：{data?.currentU}</td>
                                    <td style={{borderRight:"0px"}}>Iv：{data?.currentV}</td>
                                    <td style={{borderRight:"0px"}}>Iw：{data?.currentW}</td>
                                </tr>
                                <tr>
                                    <td width="25%" style={{borderRight:"0px"}}>转子电流：</td>
                                    <td style={{borderRight:"0px"}}>Iu：</td>
                                    <td style={{borderRight:"0px"}}>Iv：</td>
                                    <td>Iw：</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} width="50%">正向动作：{data?.actNumForward}次</td>
                                    <td colSpan={2}>累计运行：{data?.devRunTime}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} width="50%">反向动作：{data?.actNumReverse}次</td>
                                    <td colSpan={2}>设备保养：{data?.usageState}</td>
                                </tr>
                                <tr>
                                    <td colSpan={2} width="50%">故障警告：{data?.faultWarning==null||data?.faultWarning===0?'正常':data?.faultWarning}</td>
                                    <td colSpan={2}>故障保护：{data?.faultProtection==null||data?.faultProtection===0?'正常':data?.faultProtection}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                    }
                );
                setRealItems(devsList);
            }
            let realData = new Array(),series = new Array();
            for(let i=0;i<newData.devs.length;i++){
                series.push(newData.devs[i].name+"电流Iu");
                series.push(newData.devs[i].name+"电流Iv");
                series.push(newData.devs[i].name+"电流Iw");
                realData.push(newData.devs[i]?.currentU);
                realData.push(newData.devs[i]?.currentV);
                realData.push(newData.devs[i]?.currentW);
            }
            setTrackRealData(realData);
            setTrackSeries(series);
        }
    },[cardData.realData]);

    return (
        <>
            <div className={"preview-tabs-head"}  style={{height:"225px"}}>
                <Tabs defaultActiveKey="1" type="card" size={"middle"} tabBarStyle={{}} items={realItems}/>
            </div>
            <div className={"preview-tabs-head"} style={{marginTop:"10px"}}>
                <Tabs defaultActiveKey="1" type="card" size={"middle"} style={{}} onChange={(activeKey:string)=>{}}
                      items={[
                          {
                              label:<span className="preview-tabs-head-label">报警显示</span>, key:"1",
                              children:<CardHighchartsPie param={cardData.param} seriesData={cardData.param.data?.alarmSumItems} />
                          }/*, {
                                label:<span className="preview-tabs-head-label">轨迹曲线</span>, key:"2",
                                children:<CardHighchartsSpline tickPositions={null} realData={trackRealData} param={cardData.param} series={trackSeries} />
                            }*/
                      ]}
                />
            </div>
        </>
    );
};

export default JSR2TPreview;