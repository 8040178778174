import React from "react";
interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const ErrorPreview: React.FC<CardData> = (cardData) => {
    return (
        <>
            <div style={{height:'490px'}}>卡片异常，请删除后再添加!</div>
        </>
    )
}
export default ErrorPreview;