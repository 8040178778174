import {Card, Tooltip, Tabs} from 'antd';
import * as Highcharts from 'highcharts';
import React, {useState} from 'react';
import '../../../css/cardPreview.css';
import {getCardHeadPath, getParent} from "../../../utils/PublicMethodTsx";
import {alarm_hint_tooltip_css as alarmHintTooltipCss} from "../../../utils/PublicConstant";
import Icon from '@ant-design/icons';
import alarmImg from  "../../../imagers/icon/alarm.png";
import CardHeadExtra from "../../../utils/module/CardHeadExtra";
import CardEquipmentTooltip from "../../../utils/module/CardEquipmentTooltip";
import CardHighchartsSpline from "../../../utils/module/CardHighchartsSpline";
import CardHighchartsPie from "../../../utils/module/CardHighchartsPie";

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const QY3ADemoPreview: React.FC<CardData> = (cardData) => {
    const [data,setData] = useState(cardData.param.craneDataViewId === "adsasas"?
        {currentU:'1800',currentV:'1798',currentW:'1803',gearMerge:'上升4档',speed:'97%',state:'',fault:'0-无故障',acce1:false,acce2:false,acce3:false,acce4:false}:
        {currentU:'400',currentV:'396',currentW:'407',gearMerge:'上升1档',speed:'10%',state:'',fault:'0-无故障',acce1:true,acce2:true,acce3:true,acce4:false});
    React.useEffect(() => {
        let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        //setData(newData ?? data);
    },[cardData.realData]);
    React.useEffect(() => {

    },[]);
    const [open, setOpen] = useState(false);
    const getName = ( node:any)=>{
        let name = getParent(node.enterpriseList,node.key,"lable");
        let val = ""
        name.forEach((item:any,index)=> {
            if(item != "设备层级")
                val += item+"/";
        });
        return val;
    }
    return (
        <>
            <Card
                hoverable={false}
                className="card-extend"
                style={{borderRadius:"6px",border:"0px",width:"376px",margin:"auto",height:"552px",background:"var(--cardPreviewBackgroundColor)"}}
                headStyle={{background:"#ffe100",borderRadius:"6px 6px 0px 0px",minHeight:"43px",height:"43px"}}
                bodyStyle={{height:"100%",padding:"16px"}}
                extra={<CardHeadExtra setItems={cardData.setItems} setActiveKey={cardData.setActiveKey} items={cardData.items} param={cardData.param} viewOperate={cardData.viewOperate} viewTypeList={cardData.viewTypeList} setViewTypeList={cardData.setViewTypeList}/>}
                title={
                    <div className={"head-card-title"}>
                        <div className={"head-card-title-label"}>
                            <div style={{lineHeight:"19px",marginTop:"-1px"}}>{cardData.param.displayName}</div>
                        </div>
                        <div style={{flex:"1",textAlign:"left",marginLeft:"2px"}}>
                            <Tooltip title={cardData.param.path} color="#C9CACA" overlayInnerStyle={{color:"#282829",marginTop:"-8px",width:"100%"}} placement="bottom">
                                <div style={{fontSize:"11px"}}>{getCardHeadPath(cardData.param.path,"11")}</div>
                            </Tooltip>
                        </div>
                    </div>
                }
                type="inner"
            >
                <div className={"preview-tabs-head"}>
                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                        size={"middle"}
                        tabBarStyle={{}}
                        items={[
                            {
                                label:<span className="preview-tabs-head-label">主用</span>,
                                key:"1",
                                children:<div className={"preview-tabs-content"}>
                                    <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                                        <tbody>
                                        <tr>
                                            <td colSpan={2} width="50%" className="equipment-name-background">
                                                <CardEquipmentTooltip name={"设备名称"} nameLabel={"绕线电机交直流调速器"}/>
                                            </td>
                                            <td colSpan={2} width="50%">
                                                <CardEquipmentTooltip name={"型号规格"} nameLabel={""}/>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">输入档位：{data.gearMerge}</td>
                                            <td colSpan={2} width="50%">电机转速：{data.speed}</td>
                                        </tr>
                                        <tr>
                                            <td width="25%" style={{borderRight:"0px"}}>定子电流：</td>
                                            <td style={{borderRight:"0px"}}>Iu：{data.currentU}</td>
                                            <td style={{borderRight:"0px"}}>Iv：{data.currentV}</td>
                                            <td style={{borderRight:"0px"}}>Iw：{data.currentW}</td>
                                        </tr>
                                        <tr>
                                            <td width="25%" style={{borderRight:"0px"}}>定子电压：</td>
                                            <td style={{borderRight:"0px"}}>Vu：{}</td>
                                            <td style={{borderRight:"0px"}}>Vv：{}</td>
                                            <td>Vw：{}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">动作次数：{}</td>
                                            <td colSpan={2} width="50%">累计运行：</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2} width="50%">晶闸管温度：{}</td>
                                            <td colSpan={2} width="50%">设备保养：</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={4}>
                                                <div style={{display:"flex"}} id={"alarmDiv"}>
                                                    <div style={{margin:"2px 20px 0px 0px"}}>故障提示：{data.fault}</div>
                                                    <div style={{overflow:"hidden",flex:"1"}}>
                                                        <Tooltip title={data.state} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                            <div className="alarm-hint-tooltip-content"><span className="cancel-bold">{data.state}</span></div>
                                                        </Tooltip>
                                                    </div>
                                                    <div style={{margin:"0px 3px"}}>
                                                        <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px",display:data.fault === '0-无故障'?"none":"block"}}/>)}/>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td width="25%">
                                                <div style={{display:"flex"}}>
                                                    <div>一级切阻：</div>
                                                    <div className="preview-tabs-content-digital" style={{background:data.acce1?"#6eb92b":"red"}}></div>
                                                </div>
                                            </td>
                                            <td width="25%">
                                                <div style={{display:"flex"}}>
                                                    <div>二级切阻：</div>
                                                    <div className="preview-tabs-content-digital" style={{background:data.acce2?"#6eb92b":"red"}}></div>
                                                </div>
                                            </td>
                                            <td width="25%">
                                                <div style={{display:"flex"}}>
                                                    <div>三级切阻：</div>
                                                    <div className="preview-tabs-content-digital" style={{background:data.acce3?"#6eb92b":"red"}}></div>
                                                </div>
                                            </td>
                                            <td width="25%">
                                                <div style={{display:"flex"}}>
                                                    <div>制动输出：</div>
                                                    <div className="preview-tabs-content-digital" style={{background:data.acce4?"#6eb92b":"red"}}></div>
                                                </div>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            }
                        ]}
                    />
                </div>
                <div className={"preview-tabs-head"} style={{marginTop:"10px"}}>
                    <Tabs
                        defaultActiveKey="1"
                        type="card"
                        size={"middle"}
                        style={{}}
                        onChange={(activeKey:string)=>{}}
                        items={[
                            {
                                label:<span className="preview-tabs-head-label">报警显示</span>,
                                key:"1",
                                children:<CardHighchartsPie param={cardData.param} seriesData={[]} />
                            }/*, {
                                label:<span className="preview-tabs-head-label">轨迹曲线</span>,
                                key:"2",
                                children:<CardHighchartsSpline tickPositions={[0,60,120,180,240,300]} param={cardData.param} realData={[data?.currentU,data?.currentV,data?.currentW]} series={['U相电流','V相电流','W相次数']} />
                            }*/
                        ]}
                    />
                </div>
            </Card>
        </>
    );
};

export default QY3ADemoPreview;