import {Tooltip, Tabs} from 'antd';
import React, {useState} from 'react';
import '../../../css/cardPreview.css';
import {alarm_hint_tooltip_css as alarmHintTooltipCss} from "../../../utils/PublicConstant";
import Icon from '@ant-design/icons';
import alarmImg from  "../../../imagers/icon/alarm.png";
import CardEquipmentTooltip from "../../../utils/module/CardEquipmentTooltip";
import CardHighchartsPie from "../../../utils/module/CardHighchartsPie";
import CardDigitalModule from "../../../utils/module/CardDigitalModule";

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const FrequencyControlPreview: React.FC<CardData> = (cardData) => {
    const [items,setItems] = useState<any[]>([]);
    const [trackRealData,setTrackRealData] = useState<any[]>([]);
    const [trackSeries,setTrackSeries] = useState<any[]>([]);

    React.useEffect(() => {
        let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        if(newData != null && newData.motors.length > 0){
            let motors = new Array();
            for(let i=0;i<newData.motors.length;i++){
                motors.push({
                    label:<span className="preview-tabs-head-label">{newData.motors[i].name}</span>,
                    key:""+i,
                    children:<div className={"preview-tabs-content"}>
                        <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                            <tbody>
                            <tr>
                                <td colSpan={2} width="50%" className="equipment-name-background">
                                    <CardEquipmentTooltip name={"设备名称"} nameLabel={newData.motors[i]?.name}/>
                                </td>
                                <td colSpan={2} width="50%">
                                    <CardEquipmentTooltip name={"型号规格"} nameLabel={""}/>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={2} width="50%">输入档位：{newData.motors[i]?.gearMerge}</td>
                                <td colSpan={2} width="50%">电机转速：{newData.motors[i]?.speed}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} width="50%">母线电压：{newData.motors[i]?.voltage}</td>
                                <td colSpan={2} width="50%">平均电流：{newData.motors[i]?.current}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} width="50%">输出转矩：{newData.motors[i]?.torque}</td>
                                <td colSpan={2} width="50%">累计运行：{newData.motors[i]?.devRunTime}</td>
                            </tr>
                            <tr>
                                <td colSpan={2} width="50%">设备保养：{newData.motors[i]?.usageState}</td>
                                <td colSpan={2} width="50%">
                                    <CardDigitalModule digitalName="制动输出" digitalValue={newData.motors[i]?.brake}></CardDigitalModule>
                                </td>
                            </tr>
                            <tr>
                                <td colSpan={4}>
                                    <div style={{display:"flex",width:'350px'}} id={"alarmDiv"}>
                                        <div style={{margin:"2px 20px 0px 0px"}}>故障提示：{newData.motors[i]?.fault}</div>
                                        <div style={{overflow:"hidden",flex:"1"}}>
                                            <Tooltip title={""} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                <div className="alarm-hint-tooltip-content"><span className="cancel-bold"></span></div>
                                            </Tooltip>
                                        </div>
                                        <div style={{margin:"0px 3px"}}>
                                            <Icon component={() => (<img src={alarmImg} style={{display:"none",width:"13px",height:"13px"}}/>)}/>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                });
                setItems(motors);
            }
            let realData = new Array(),series = new Array();
            for(let i=0;i<newData.motors.length;i++){
                series.push(newData.motors[i].name+"转速");
                series.push(newData.motors[i].name+"电压");
                series.push(newData.motors[i].name+"电流");
                realData.push(newData.motors[i]?.speed);
                realData.push(newData.motors[i]?.voltage);
                realData.push(newData.motors[i]?.current);
            }
            setTrackRealData(realData);
            setTrackSeries(series);
        }
    },[cardData.realData]);
    return (
        <>
            <div className={"preview-tabs-head"} style={{height:"225px"}}>
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size={"middle"}
                    tabBarStyle={{}}
                    items={items}
                />
            </div>
            <div className={"preview-tabs-head"} style={{marginTop:"10px"}}>
                <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size={"middle"}
                    style={{}}
                    onChange={(activeKey:string)=>{}}
                    items={[
                        {
                            label:<span className="preview-tabs-head-label">报警显示</span>,
                            key:"1",
                            children:<CardHighchartsPie param={cardData.param} seriesData={cardData.param.data?.alarmSumItems} />
                        }/*, {
                                label:<span className="preview-tabs-head-label">轨迹曲线</span>,
                                key:"2",
                                children:<CardHighchartsSpline realData={trackRealData} param={cardData.param} series={trackSeries}/>
                            }*/
                    ]}
                />
            </div>
        </>
    );
};

export default FrequencyControlPreview;