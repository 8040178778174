import React, {useState} from "react";
import {Tooltip, Tabs} from 'antd';
import {getCardDigitalMaintain} from "../../../utils/PublicMethodTsx";
import CardHighchartsPie from "../../../utils/module/CardHighchartsPie";
import {alarm_hint_tooltip_css as alarmHintTooltipCss} from "../../../utils/PublicConstant";
import Icon from "@ant-design/icons";
import alarmImg from "../../../imagers/icon/alarm.png";

interface CardData{
    param:any,
    realData:any,
    setItems:any,
    setActiveKey:any,
    items:any,
    viewTypeList:any,
    setViewTypeList:any;
    viewOperate:any;
}
const CranePreview: React.FC<CardData> = (cardData) => {
    const [realItems,setRealItems] = useState<any[]>([]);
    const [trackRealData,setTrackRealData] = useState<any[]>([]);
    const [trackSeries,setTrackSeries] = useState<any[]>([]);
    const [tabsKey,setTabsKey] = useState("1");
    const motionName:{ [key: string]: any } = {"hst1":"主起升","hst2":"副起升","hst3":"副副起升","trv1":"大车","trv2":"主小车","trv3":"副小车"};
    React.useEffect(() => {
        let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        if(newData != null) {
            let devsList = new Array();
            let data = [{name:"起升",key:"1",data:newData.hstData},{name:"平移",key:"2",data:newData.trvData}];
            data.map(item=>{
                devsList.push({
                    label: <span className="preview-tabs-head-label">{item.name}</span>,
                    key: item.key,
                    children:<div className={"preview-tabs-content"}>
                        <table className="table table-borderless" style={{fontSize: '12px',width: '100%',fontWeight: "bold"}}>
                            <tbody>
                                {
                                    item.data.map((item2:any)=>{
                                        let key = item2.type;
                                        return <tr key={item2.type}>
                                            <td width="25%">{motionName[key]}</td>
                                            <td width="25%">速度：{item2.speed!=null?item2.speed:''}</td>
                                            <td width="25%">{item.key=="1"?'高度':'距离'}：{item2.distance!=null?item2.distance:''}</td>
                                            <td width="25%">{item.key=="1"&&<span>重量：{item2.weight!=null?item2.weight:''}</span>}</td>
                                        </tr>;
                                    })
                                }
                                <tr>
                                    <td colSpan={3}>
                                        <div style={{display:"flex",width:'260px'}} id={"alarmDiv"}>
                                            <div style={{margin:"2px 20px 0px 0px"}}>故障提示：{newData?.alarmInfo?.fault}</div>
                                            <div style={{overflow:"hidden",flex:"1"}}>
                                                <Tooltip title={newData?.alarmInfo?.state} color="#C9CACA" overlayInnerStyle={alarmHintTooltipCss} placement="bottom">
                                                    <div className="alarm-hint-tooltip-content"><span className="cancel-bold">{newData?.alarmInfo?.state}</span></div>
                                                </Tooltip>
                                            </div>
                                            <div style={{margin:"0px 3px"}}>
                                                <Icon component={() => (<img src={alarmImg} style={{width:"13px",height:"13px",display:newData?.alarmInfo?.fault === '0-无故障'?"none":"block"}}/>)}/>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div style={{display:"flex"}}>
                                            <div>保养：</div>
                                            <div className="preview-tabs-content-digital" style={{background:getCardDigitalMaintain(newData?.usageState)}}></div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>累计工作循环</td>
                                    <td>{newData?.accumulatedWorkCycle}</td>
                                    <td>累计工作时间</td>
                                    <td>{newData?.accumulatedWorkTime}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                });
            });
            setRealItems(devsList);
            let realData = new Array(),series = new Array();
            data.map(item=>{
                if(item.key == tabsKey){
                    item.data.map((item2:any)=>{
                        series.push(motionName[item2.type]+"速度");
                        series.push(motionName[item2.type]+(item.key=="1"?'高度':'距离'));
                        realData.push(item2.speed);
                        realData.push(item2.distance);
                        if(item.key == "1"){
                            series.push(motionName[item2.type]+"重量");
                            realData.push(item2.weight);
                        }
                    });

                }
            });
            setTrackRealData(realData);
            setTrackSeries(series);
        }

    },[cardData.realData]);
    return (
        <>
            <div className={"preview-tabs-head"}  style={{height:"225px"}}>
                <Tabs activeKey={tabsKey} type="card" size={"middle"} tabBarStyle={{}} onChange={(key: string)=>{setTabsKey(key);}} items={realItems}/>
            </div>
            <div className={"preview-tabs-head"} style={{marginTop:"10px"}}>
                <Tabs defaultActiveKey="1" type="card" size={"middle"}
                      items={[
                          {
                              label:<span className="preview-tabs-head-label">报警显示</span>, key:"1",
                              children:<CardHighchartsPie param={cardData.param} seriesData={cardData.param.data?.alarmSumItems} />
                          }/*, {
                                  label:<span className="preview-tabs-head-label">轨迹曲线</span>, key:"2",
                                  children:<CardHighchartsSpline tabsKey={tabsKey} tickPositions={null} realData={trackRealData} param={cardData.param} series={trackSeries} />
                              }*/
                      ]}
                />
            </div>
        </>
    )
}
export default CranePreview;