import React, {useState} from 'react';
import * as Highcharts from "highcharts";
import {getSplineValue} from "../PublicMethodTsx";

interface CardData{
    tabsKey?:string;
    param:any;
    series:any;
    realData?:any;
    tickPositions?:any;
}
const CardHighchartsSpline: React.FC<CardData> = (cardData) => {
    const [highcharts,setHighcharts] = useState<any>();
    const [tabsKey,setTabsKey] = useState<string>("");

    React.useEffect(() => {
        //let newData = cardData.realData?.find((item:any) => item.id == cardData.param.id)?.data;
        if(cardData.realData != null && highcharts != null){
            if(cardData.tabsKey != null && tabsKey != "" && tabsKey != cardData.tabsKey)
                createHighcharts();
            if(cardData.tabsKey != null)
                setTabsKey(cardData.tabsKey);
            /* let i = 0;
             highcharts.series?.map((item:any) => {
                 let value = null;
                 if(i == 0)
                     value = newData.currentU;
                 else if(i == 1)
                     value = newData.currentV;
                 else if(i == 2)
                     value = newData.currentW;
                 if(value != null && value != "")
                     item.addPoint([(new Date()).getTime(), Number(value.slice(0, -2))], true, true,false);
                 i++;
             });*/
            let i = 0;
            let time = (new Date()).getTime();
            highcharts.series?.map((item:any) => {
                let value = cardData.realData[i]??'';
                if(cardData.realData.length > 0 && value != ''){
                    item.addPoint([time, getSplineValue(value)], true, true,false);
                }else{
                    item.addPoint([time, null], true, true,false);
                }
                i++;
            });
        }
    },[cardData.realData]);

    const getData = ()=>{
        const seriesData = new Array();
        cardData.series.map((item:any) => {
            seriesData.push({
                dataLabels: {
                    enabled: true
                },
                name: item,
                marker: {
                    enabled: false,
                },
                data: (function () {
                    // 生成随机值
                    let data = [],
                        time = (new Date()).getTime(),
                        i;
                    for (i = -10; i <= 0; i += 1) {
                        data.push({
                            x: time + i * 1000,
                            y: null
                        });
                    }
                    return data;
                }())
            });
        });
       return seriesData;
    }
    const createHighcharts = ()=>{
        (Highcharts as any).setOptions({global: {useUTC: false}});
        setHighcharts((Highcharts as any).chart(document.getElementById(cardData.param.id+'preview2'), {
            credits:{enabled:false},
            chart: {
                plotBackgroundColor: null,
                plotBorderWidth: null,
                plotShadow: false,
                backgroundColor:"#e6e6e6",
                type: 'spline',
                marginRight: 10,
                events: {
                    load: function () {
                        /*setInterval(()=>{
                            if(highchartsRef != null){
                                 let series = highchartsRef.series[0], chart = this;
                                 activeLastPointToolip(chart,0);
                                 let x = (new Date()).getTime(),
                                     y = Math.random()*100;
                                 series.addPoint([x, y], true, true);
                                 activeLastPointToolip(chart,0);
                            }else
                                console.log(highchartsRef)
                         },1000);*/
                    }
                }
            },
            title: {
                text: ''
            },
            xAxis: {
                type: 'datetime',
                tickPixelInterval: 150
            },
            yAxis: {
                title: {
                    text: "数值"
                },
                tickPositions: cardData.tickPositions,
            },
            tooltip: {
                formatter: function () {
                    return '<b>' + (this as any).series.name + '</b><br/>' +
                        Highcharts.dateFormat('%Y-%m-%d %H:%M:%S', (this as any).x) + '<br/>' +
                        Highcharts.numberFormat((this as any).y, 2);
                }
            },
            legend: {
                enabled: true
            },
            series: getData()
        }));
    }
    React.useEffect(() => {
        createHighcharts();
    },[]);
    return (
        <>
            <div style={{border: "1px solid #a3a3a3"}}></div>
            <div id={cardData.param.id+'preview2'} style={{height:'226px',background:"#e6e6e6"}}></div>
        </>
    );
};

export default CardHighchartsSpline;