import React from 'react';
import {getCardDigital} from "../PublicMethodTsx";

interface CardData{
    digitalName:string;
    digitalValue:boolean | null;
}
const CardDigitalModule: React.FC<CardData> = (cardData) => {
    return (
        <>
            <div style={{display:"flex"}}>
                <div>{cardData.digitalName}：</div>
                <div className="preview-tabs-content-digital" style={{background:getCardDigital(cardData.digitalValue)}}></div>
            </div>
        </>
    );
};

export default CardDigitalModule;